<template>
  <v-container>
    <v-row class="hide-at-print">
      <v-col cols="12">
        <p class="text-center font-weight-light text-h3">
          Alle Daten eines Users einsehen
        </p>
      </v-col>
    </v-row>
    <v-row class="hide-at-print">
      <v-col>
        <v-card>
          <v-card-text>
            <v-select
              v-model="selectedUser"
              item-text="companyName"
              return-object
              :items="allUsers"
              label="Bitte ein Unternehmen wählen"
              @change="getDataEntrysYear"
              solo
            ></v-select>
            <v-select
              v-model="selectedYear"
              :items="yearsToSelect"
              label="Bitte ein Jahr wählen"
              @change="getDataEntrysYear"
              solo
            >
            </v-select>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="selectedUser !== null" class="hide-at-print">
      <v-col>
        <user-information-overview-for-admin :selectedUser="selectedUser" />
      </v-col>
    </v-row>
    <v-row v-if="selectedUser !== null" class="hide-at-print">
      <v-col>
        <data-entry-overview
          :isAdmin="true"
          :email="selectedUser.email"
          :showSelector="false"
          :year="selectedYear"
        />
      </v-col>
    </v-row>
    <v-row class="hide-at-print">
      <v-col>
        <v-card v-if="dataFromOneYear != null" class="pa-4">
          <v-card-title>
            Daten des Jahres {{ selectedYear }} der Unternehmens
            {{ selectedUser.companyName }} aufgelistet
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headersDataTableOneYear"
              :items="dataFromOneYear"
              item-key="id"
              style="height: auto"
            >
            </v-data-table>
            <v-btn
              block
              @click="exportToExcel()"
              class="hide-at-print mt-4"
              color="primary"
            >
              Daten für das Jahr {{ selectedYear }} des nutzers
              {{ selectedUser.companyName }} Exportieren
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="selectedUser !== null">
      <v-col cols="12">
        <p class="text-center font-weight-light text-h3">
          Datenvisualisierung Umsatzmonitor
        </p>
        <p class="text-center font-weight-light text-h4">
          {{ selectedUser.companyName }}
        </p>
      </v-col>
    </v-row>
    <v-row v-if="selectedUser !== null">
      <v-col>
        <year-comparison-chart :email="selectedUser.email" :isAdmin="true" />
      </v-col>
    </v-row>
    <v-row v-if="selectedUser !== null" class="new-page">
      <v-col>
        <data-current-year-comp-to-others
          :isAdmin="true"
          :selectedUser="selectedUser"
          :selectedYear="selectedYear"
        />
      </v-col>
    </v-row>
    <v-row v-if="selectedUser !== null" class="new-page">
      <v-col>
        <trend-line-comp-to-others
          :isAdmin="true"
          :selectedUser="this.selectedUser"
          :selectedYear="selectedYear"
        />
      </v-col>
    </v-row>
    <v-row v-if="selectedUser !== null" class="new-page">
      <v-col>
        <comp-tow-years-to-others
          :isAdmin="true"
          :selectedUser="this.selectedUser"
          :selectedYear="selectedYear"
        />
      </v-col>
    </v-row>
    <v-row v-if="selectedUser !== null" class="new-page">
      <v-col>
        <comp-tow-years
          :selectedUser="selectedUser"
          :isAdmin="true"
          :selectedYear="selectedYear"
        />
      </v-col>
    </v-row>
    <v-row v-if="selectedUser !== null" class="new-page">
      <v-col>
        <comp-tow-years-quartal
          :selectedUser="selectedUser"
          :isAdmin="true"
          :selectedYear="selectedYear"
        />
      </v-col>
    </v-row>
    <v-row v-if="selectedUser !== null" class="new-page">
      <v-col>
        <cart-value-years-comprison
          :email="selectedUser.email"
          :isAdmin="true"
        />
      </v-col>
    </v-row>

    <v-row v-if="selectedUser !== null" class="new-page">
      <v-col>
        <cart-value-years-comparison-quartal
          :email="selectedUser.email"
          :isAdmin="true"
        />
      </v-col>
    </v-row>
    <v-row v-if="selectedUser !== null" class="new-page">
      <v-col>
        <current-year-chart
          :email="selectedUser.email"
          :isAdmin="true"
          :selectedYear="selectedYear"
        />
      </v-col>
    </v-row>
    <v-btn class="mt-5 hide-at-print" color="primary" block @click="exportPDF">
      Bericht als PDF Speichern</v-btn
    >
  </v-container>
</template>

<script>
import * as XLSX from "xlsx";
import axios from "axios";
import YearComparisonChart from "../../components/evaluationCharts/YearComparisonChart.vue";
import CartValueYearsComprison from "../../components/evaluationCharts/CartValueYearsComprison.vue";
import CartValueYearsComparisonQuartal from "../../components/evaluationCharts/CartValueYearsComparisonQuartal.vue";
import CurrentYearChart from "../../components/evaluationCharts/CurrentYearChart.vue";
import DataCurrentYearCompToOthers from "../../components/evaluationCharts/DataCurrentYearCompToOthers.vue";
import CompTowYears from "../../components/evaluationCharts/CompTowYears.vue";
import CompTowYearsQuartal from "../../components/evaluationCharts/CompTowYearsQuartal.vue";
import DataEntryOverview from "../../components/evaluationCharts/DataEntryOverview.vue";
import CompTowYearsToOthers from "../../components/evaluationCharts/CompTowYearsToOthers.vue";
import TrendLineCompToOthers from "../../components/evaluationCharts/TrendLineCompToOthers.vue";
import UserInformationOverviewForAdmin from "../../components/userInformationOverviewForAdmin.vue";

export default {
  name: "insertData",
  components: {
    YearComparisonChart,
    CartValueYearsComprison,
    CartValueYearsComparisonQuartal,
    CurrentYearChart,
    DataCurrentYearCompToOthers,
    CompTowYears,
    CompTowYearsQuartal,
    DataEntryOverview,
    CompTowYearsToOthers,
    TrendLineCompToOthers,
    UserInformationOverviewForAdmin,
  },
  data() {
    return {
      allUsers: [],
      selectedUser: null,
      yearsToSelect: [],
      selectedYear: new Date().getFullYear(),
      statusDataEntrys: null,
      dataFromOneYear: null,
      headersDataTableOneYear: [
        { text: "KW", value: "kw", align: "center" },
        {
          text: "Netto-Umsatz Obst, Gemüse und Kartoffeln",
          value: "nettoUmsObstGemKartof",
          align: "center",
        },
        {
          text: "Netto-Umsatz Sonstiges",
          value: "nettoUmsSonstige",
          align: "center",
        },
        { text: "Umsatz Kombiniert", value: "summe", align: "center" },
        {
          text: "Anzahl Bestellungen",
          value: "numberOfOrders",
          align: "center",
        },
      ],
    };
  },
  methods: {
    getDataEntrysYear: async function () {
      if (this.selectedUser === null || this.selectedYear === null) {
        return;
      }

      let returnDataStatus = [];
      const res = await axios.post(
        this.$store.state.apiUrl + "/api/admin/getCompaniDataYear",
        {
          year: this.selectedYear,
          email: this.selectedUser.email,
        }
      );

      for (let y = 0; y < 53; y++) {
        returnDataStatus.push({
          year: this.selectedYear,
          kw: y + 1,
          dataEntry: "error",
        });
      }
      //insetring entrys alredy made
      for (let y = 0; y < res.data.weeksArray.length; y++) {
        returnDataStatus[res.data.weeksArray[y].kw - 1].dataEntry = "success";
      }
      this.dataFromOneYear = res.data.weeksArray;
      this.statusDataEntrys = returnDataStatus;
    },
    exportToExcel: function () {
      this.dataFromOneYear;

      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Exportig DB data" + this.selectedUser.companyName,
        Author: "Umsatzmonitor 2.0",
      };

      var ws = XLSX.utils.json_to_sheet(
        this.dataFromOneYear.map((wa) => {
          delete wa._id;
          let rObj = {};
          rObj.KW = wa.kw;
          rObj.nettoUmsObstGemKartof = wa.nettoUmsObstGemKartof;
          rObj.nettoUmsSonstige = wa.nettoUmsSonstige;
          rObj.summe = wa.summe;
          rObj.numberOfOrders = wa.numberOfOrders;
          return rObj;
        })
      );

      XLSX.utils.book_append_sheet(wb, ws, "Umsaetze");

      //var wb_out =
      XLSX.writeFile(
        wb,
        "Daten Export für das Jahr " +
          this.selectedYear +
          " von " +
          this.selectedUser.companyName +
          ".xlsx"
      );
    },
    generateYearsToSelectArray: function (
      yearPreset = new Date().getFullYear()
    ) {
      let years = [];
      for (let i = 0; i < 7; i++) {
        years.push(yearPreset - i);
      }
      return years;
    },
    exportPDF: async function () {
      window.print();
    },
  },
  mounted() {
    //Get the current Year as an Integer
    let currentYear = new Date().getFullYear();
    for (let i = 0; i < 7; i++) {
      this.yearsToSelect.push(currentYear - i);
    }

    axios
      .post(this.$store.state.apiUrl + "/api/admin/getAllUsers")
      .then((res) => {
        this.allUsers = res.data.allUser;
      });
  },
};
</script>

<style>
@media print {
  canvas {
    max-width: 100%;
    width: auto !important;
    height: auto !important;
    size: landscape;
  }
  .new-page {
    page-break-before: always !important;
  }
  .hide-at-print {
    display: none !important;
  }
}
@page {
  size: landscape !important;
}
</style>
