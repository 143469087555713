<template>
  <v-card>
    <v-card-title class="ms-4">
      {{ selectedUser.companyName }}
    </v-card-title>
    <v-card-text>
      <v-list-item v-for="(value, name, index) in selectedUser" :key="index">
        <v-list-item-content>
          <v-list-item-title>
            {{ value }}
          </v-list-item-title>
          <v-list-item-subtitle> {{ name }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
    <v-card-text>
      <v-dialog v-model="dialog" width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" block v-bind="attrs" v-on="on">
            Nutzerdaten Bearbeiten
          </v-btn>
        </template>
        <v-card>
          <v-card-text
            v-for="userInfo in userInfos"
            :key="userInfo.index"
            class="justify-center align-center mt-3"
          >
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  v-model="userInfo.variable"
                  :label="userInfo.description"
                  :disabled="userInfo.disabledInput"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  block
                  @click="editEntry(userInfo)"
                  :color="userInfo.disabledInput ? '' : 'success'"
                  :loading="userInfo.loading"
                >
                  {{ userInfo.disabledInput ? "Bearbeiten" : "Speichern" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="justify-center align-center">
            <v-row>
              <v-col>
                <v-switch
                  :disabled="notification.disabledInput"
                  v-model="notification.variable"
                  label="Wöchentliche E-Mail Erinnerungen"
                  inset
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  block
                  :color="notification.disabledInput ? '' : 'success'"
                  @click="editEntry(notification)"
                  :loading="notification.loading"
                >
                  {{ notification.disabledInput ? "Bearbeiten" : "Speichern" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Fenster schließen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dialog: false,
      userInfos: [
        {
          index: 0,
          variable: this.selectedUser.companyName,
          variableName: "companyName",
          description: "Firmenname",
          disabledInput: true,
          loading: false,
        },
        {
          index: 1,
          variable: this.selectedUser.email,
          variableName: "email",
          description: "E-Mail Adresse",
          disabledInput: true,
          loading: false,
        },
      ],
      notification: {
        index: 2,
        variable: this.selectedUser.emailNotification,
        variableName: "emailNotification",
        description: "Erinnerungs ",
        disabledInput: true,
        loading: false,
      },
    };
  },
  components: {},
  props: {
    selectedUser: {
      type: Object,
      required: true,
    },
  },
  watch: {
    selectedUser: function () {
      this.userInfos = [
        {
          index: 0,
          variable: this.selectedUser.companyName,
          variableName: "companyName",
          description: "Firmenname",
          disabledInput: true,
          loading: false,
        },
        {
          index: 1,
          variable: this.selectedUser.email,
          variableName: "email",
          description: "E-Mail Adresse",
          disabledInput: true,
          loading: false,
        },
      ];
      this.notification = {
        index: 2,
        variable: this.selectedUser.emailNotification,
        variableName: "emailNotification",
        description: "Erinnerungs ",
        disabledInput: true,
        loading: false,
      };
    },
  },
  methods: {
    async editEntry(infoObj) {
      if (infoObj.disabledInput) {
        infoObj.disabledInput = false;
        return;
      }

      infoObj.loading = true;
      try {
        let user = this.selectedUser;
        let sendableObj = {};
        sendableObj[infoObj.variableName] = infoObj.variable;
        sendableObj.orgEmail = user.email;
        const res = await axios.post(
          this.$store.state.apiUrl + "/api/users/changeAccountInfoAdmin",
          sendableObj
        );
        if (res.data === "success") {
          this.selectedUser[infoObj.variableName] = infoObj.variable;
          infoObj.disabledInput = true;
        } else {
          infoObj.variable = user[infoObj.variableName];
          infoObj.disabledInput = true;
          this.errorMessage = "Something went wrong";
        }
      } catch (e) {
        this.errorMessage = "Something went wrong";
        console.log(e);
      }

      infoObj.loading = false;
    },
  },
  mounted: async function () {},
};
</script>
<style></style>
