<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Trendlinie des Betriebes im Vergleich zum Durchschnitt im Jahr
      {{ selectedYear }}
    </v-card-title>
    <v-card-title class="mt-n5 mb-3 hide-at-print">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on"> mdi-help-circle </v-icon>
        </template>
        <span>
          Die Trendline entsteht mit der Berechnung der Umsatzentwicklung von
          einer KW zur Vorwoche, <br />
          anschließend werden dann die Durchschnitte dieser Entwicklungen
          gebildet.<br />
          Abschließend werden die Prozentzahlen zur Vorwoche aufaddiert.
          <br />
          Beispiel: Entwicklung zur Vorwoche: 0%, 5%, -10%, 2%.... Trendlinie:
          0%, 5%, -5% , -3%....
          <br />
          Aufgrund der geringen Liefermengen nach Silvester ist der Anstieg von
          KW 1 zu 2 immer hoch und wird zur besseren Vergleichbarkeit ignoriert.
          <br />
          Werte über oder unter 200% werden ignoriert.
        </span>
      </v-tooltip>
    </v-card-title>
    <v-card-text v-if="!loadingData">
      <custom-chart unit=" % " :chartdata="cahrtData" />
    </v-card-text>
  </v-card>
</template>

<script>
import generateChartData from "../../utils/generateChartData";
import customChart from "../../components/customChart.vue";

export default {
  data() {
    return {
      cahrtData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
    };
  },
  components: {
    customChart,
  },
  props: {
    selectedUser: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    selectedYear: {
      type: Number,
      required: true,
    },
  },
  watch: {
    selectedUser: async function () {
      await this.setupChartData();
    },
    selectedYear: async function () {
      await this.setupChartData();
    },
  },
  methods: {
    generateAPIPath: function () {
      if (this.isAdmin) {
        return "/api/admin/";
      }
      return "/api/getDataUmsatzmonitor/";
    },
    setupChartData: async function () {
      this.loadingData = true;
      //Labels Vorbereiten

      //Größen Wörterbuch bereitstellen:
      let companySizeDictionary = {
        Big: "Große",
        Medium: "Mittelgroße",
        Small: "Kleine",
      };

      //Region Wörterbuch bereitstellen:
      let companyRegionDictionary = {
        South: "Süden",
        North: "Norden",
      };

      //Größenfattegorie ins Deutsche Übersetzen
      let companySizeDE = companySizeDictionary[this.selectedUser.companySize];

      //Region ins Deutsche Übersetzen
      let regionDE = companyRegionDictionary[this.selectedUser.region];

      let dataReq = [];
      dataReq.push({
        apiQuerryPath: this.apiPath + "getCompanyTrendLine",
        apiQuerryData: {
          year: this.selectedYear,
          email: this.selectedUser.email,
        },
        labels: [this.selectedUser.companyName],
        subVariable: ["diff"],
      });
      dataReq.push({
        apiQuerryPath: this.apiPath + "getTrendLineFiltered",
        apiQuerryData: {
          year: this.selectedYear,
          email: this.selectedUser.email,
          filter: {
            thresholdAverage: 8,
          },
        },
        labels: ["Ø Alle"],
        subVariable: ["diff"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });

      dataReq.push({
        apiQuerryPath: this.apiPath + "getTrendLineFiltered",
        apiQuerryData: {
          year: this.selectedYear,
          email: this.selectedUser.email,
          filter: {
            filterOnCompanySize: true,
          },
        },
        labels: ["Ø " + companySizeDE + " Unternehmen"],
        subVariable: ["diff"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });
      dataReq.push({
        apiQuerryPath: this.apiPath + "getTrendLineFiltered",
        apiQuerryData: {
          year: this.selectedYear,
          email: this.selectedUser.email,
          filter: {
            filterOnRegion: true,
          },
        },
        labels: ["Ø " + regionDE],
        subVariable: ["diff"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });
      dataReq.push({
        apiQuerryPath: this.apiPath + "getTrendLineFiltered",
        apiQuerryData: {
          year: this.selectedYear,
          email: this.selectedUser.email,
          filter: {
            filterOnFederalState: true,
          },
        },
        labels: ["Ø " + this.selectedUser.federalState],
        subVariable: ["diff"],
        tooltipInfo: {
          variable: "count",
          appendText: " Einträge ergeben diesen Durchschnitt",
        },
      });

      this.cahrtData = await generateChartData(dataReq);
      this.loadingData = false;
    },
  },

  mounted: async function () {
    await this.setupChartData();
  },
};
</script>
<style></style>
